export default {
    //设置菜单列表
    setMenuList: "setMenuList",
    //设置菜单样式
    changeMenuTheme: "changeMenuTheme",
    //设置风格颜色
    changeMainTheme: "changeMainTheme",
    //设置首页
    setHomePage: "setHomePage",
    //打开页面
    openPage: "openPage",
    //打开页面
    closePage: "closePage",
    //关闭其他页面
    closeOtherPage: "closeOtherPage",
    //关闭所有打开页
    closeAllPage: "closeAllPage",
    //设置tab名称
    setTabTitle: "setTabTitle",
    //设置post status
    setPostStatus: "setPostStatus",
    //打开确认提示
    openConfirm: "openConfirm",
    //打开确认提示
    closeConfirm: "closeConfirm",
    //显示message
    showMessage: "showMessage",
}

/**
 * 页面payload
 */
export interface PagePayload {
    path: string,
    name?: string | null
    title: string,
    current?: boolean
    edit?: boolean
}

export interface ConfirmPayload {
    // show?: boolean,
    callback: (value: boolean | PromiseLike<boolean>) => void,
    text?: string
}

export interface MessagePayload {
    text?: string,
    color?: string,
    timeout?: number,
}
