import Vue from 'vue';
import Router, {Route, RouterOptions} from 'vue-router';
import routes from './routes';
import security from '../plugins/security';
import globalFunc from '../store/globalFunc';

Vue.use(Router);
// 路由配置
const RouterConfig: RouterOptions = {routes};
const router = new Router(RouterConfig);
router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth ?? true)) {
        if (await security.auth(to.meta ? to.meta.auth : null)) {
            next();
        } else if (to.path === "/") {
            next({path: '/login'});
        } else
            next({path: '/'});
    } else {
        next();
    }
});

const openNewPage = (vm: Vue, to: Route) => {
    if (to.meta.title === undefined || to.name === "home")
        return;
    vm.$store.commit(globalFunc.openPage, {
        name: to.name,
        path: to.path,
        title: to.meta.title
    });
};

router.afterEach((to) => {
    openNewPage(router.app, to);
});

export default router;
