import main from '@/views/index.vue';
import dolphin from "./dolphin";

export default [
    {
        path: '/login',
        name: 'login',
        meta: {requiresAuth: false},
        component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue')
    },
    {path: '/', children: dolphin, component: main},
    {path: '*', redirect: '/'}
];
