import security from "./security/security";
import apply from "./apply/apply";
import service from "./service/service";
import serviceFile from "./file/file";
import port from "./common/port";
import company from "./core/company";
import user from "./core/user";
import userRole from "./core/userRole";
import team from "@/core/service/core/team";
import ais from "@/core/service/ais/ais";
import aisTrack from "./service/aisTrack";
import operator from "./core/operator";
import oldService from "@/core/service/service/oldService";
import report from "@/core/service/service/report";
import reportVoyage from "@/core/service/report/reportVoyage";
import reportDaily from "@/core/service/report/reportDaily";
import reportVoyageinfo from "@/core/service/report/reportVoyageinfo";
import reportWeather from "@/core/service/report/reportWeather";
import reportWeatherFile from "@/core/service/report/reportWeatherFile";
import reportRouting from "@/core/service/report/reportRouting";
import reportRoutingFile from "@/core/service/report/reportRoutingFile";
import route from "@/core/service/service/route";
import country from "@/core/service/common/country";
import userTeam from "@/core/service/core/userTeam";

export default {
    security,
    apply,
    service,
    serviceFile,
    port,
    company,
    user,
    ais,
    team,
    userRole,
    aisTrack,
    operator,
    oldService,
    report,
    reportVoyage,
    reportDaily,
    reportWeather,
    reportWeatherFile,
    reportVoyageinfo,
    reportRouting,
    reportRoutingFile,
    route,
    country,
    userTeam
}
