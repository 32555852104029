<template>
  <v-row dense no-gutters>
    <v-col v-bind="$attrs">
      <v-subheader class="v-label flex-row-reverse">{{ label }}</v-subheader>
    </v-col>
    <v-col class="v-wrap">
      <slot/>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  inheritAttrs: true,
  name: "v-wrap",
  props: {
    label: {type: String},
  },
});
</script>
