<template>
  <v-dialog @click:outside="reset" v-model="show" max-width="300" transition="false">
    <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block">
      <div class="text--lighten-1 text-body-2 mb-4">{{ text }}</div>
      <v-btn class="ma-1" color="error" plain @click="reset"> 确认</v-btn>
    </v-sheet>
  </v-dialog>
</template>
<script lang="ts">
import Vue from "vue";
import tips from "@/configure/tip";
import globalFunc from "@/core/store/globalFunc";

export default Vue.extend({
  name: "HttpErrorDialog",
  data() {
    return {
      text: ""
    }
  },
  methods: {
    reset() {
      this.$store.commit(globalFunc.setPostStatus, 0);
    }
  },
  computed: {
    show(): boolean {
      if (this.$store.state.standard.postStatus >= 400) {
        //@ts-ignore
        this.text = tips.post[this.$store.state.standard.postStatus] ?? tips.post[999];
        return true;
      } else
        return false;
    },
  }
});
</script>
