import Vue from "vue";
import security from "@/core/plugins/security";
import globalFunc from "@/core/store/globalFunc";
import menu from "@/configure/menu";
import HttpErrorDialog from "./standard/http-error-dialog.vue";
import ConfirmDialog from "./standard/confirm-dialog.vue";
import MessageSnackbar from "@/views/standard/message-snackbar.vue";

function menuFilter(auth: (name: string) => boolean) {
    const re = [];
    for (let item of menu) {
        if (item.children) {
            const children = item.children.filter(value => !value.auth || auth(value.auth));
            if (children.length !== 0)
                re.push({...item, children});
        } else if (!item.auth || auth(item.auth)) {
            re.push(item);
        }
    }
    return re;
}

export default Vue.extend({
    name: "index",
    components: {
        MessageSnackbar,
        ConfirmDialog,
        HttpErrorDialog,
    },
    data() {
        return {
            drawer: true,
            dialog: {
                logout: false
            },
            group: null,
            keepList: this.$store.state.standard.pageOpenedList,
            style: {
                sheet: {
                    height: 'calc(100vh - 48px - 48px)'
                }
            },
            items: menuFilter(this.$auth),
        }
    },
    methods: {
        async logout() {
            await security.logout();
            this.$store.commit(globalFunc.closeAllPage);
            await this.$router.push("/login");
        },
        async menuClick(path: string) {
            await this.$router.push({path}).catch(err => err);
        },
        async tabClick(path: string) {
            await this.$router.push({path}).catch(err => err);
        },
        async tabClose(path: string) {
            this.$store.commit(globalFunc.closePage, path);
            if (this.$route.path === path) {
                const tab: number = this.$store.state.standard.pageOpening;
                if (this.keepList.length === 0)
                    await this.$router.push({path: '/'});
                else if (tab === 0)
                    await this.$router.push({path: this.keepList[tab].path});
                else if (tab >= this.keepList.length)
                    await this.$router.push({path: this.keepList[this.keepList.length - 1].path});
                else
                    await this.$router.push({path: this.keepList[tab - 1].path});
            }
        },
    },
    watch: {
        '$vuetify.application.top'(to) {
            const tabsHeight = (this.$refs.tabs as Vue).$el.clientHeight;
            this.style.sheet.height = 'calc(100vh - ' + (tabsHeight + to) + 'px)';
        }
    }
});
