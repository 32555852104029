<template>
  <v-dialog v-model="$store.state.standard.confirm.show" max-width="300" transition="false">
    <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center">
      <div class="text--lighten-1 text-body-2 mb-4">
        {{ $store.state.standard.confirm.text }}
      </div>
      <v-btn class="ma-1" color="error" plain @click="ok">确认</v-btn>
      <v-btn class="ma-1" plain @click="cancel">取消</v-btn>
    </v-sheet>
  </v-dialog>
</template>
<script lang="ts">
import Vue from "vue";
import globalFunc from "@/core/store/globalFunc";

export default Vue.extend({
  name: "ConfirmDialog",
  data: () => ({}),
  methods: {
    ok() {
      this.$store.commit(globalFunc.closeConfirm, true);
    },
    cancel() {
      this.$store.commit(globalFunc.closeConfirm, false);
    }
  }
});
</script>
