export default [
    {
        path: 'service',
        name: 'service',
        meta: {auth: 'service.service.query', title: '业务服务'},
        component: () => import(/* webpackChunkName: "service.query" */ '@/views/service/service/query.vue')
    },
    {
        path: 'service/add',
        name: 'service.add',
        meta: {auth: 'service.service.add', title: '服务新增'},
        component: () => import(/* webpackChunkName: "service.info" */ '@/views/service/service/info.vue')
    },
    {
        path: 'service/load/:key',
        name: 'service.load',
        meta: {auth: 'service.service.load', title: '服务查看'},
        component: () => import(/* webpackChunkName: "service.load" */ '@/views/service/service/info.vue')
    },
    {
        path: 'service/edit/:key',
        name: 'service.edit',
        meta: {auth: 'service.service.save', title: '服务编辑'},
        component: () => import(/* webpackChunkName: "service.save" */ '@/views/service/service/info.vue')
    },
    {
        path: 'service/report/:key',
        name: 'serviceReport',
        meta: {auth: 'service.service.save', title: '报告维护'},
        component: () => import(/* webpackChunkName: "service.save" */ '@/views/service/report/report.vue')
    },
];
