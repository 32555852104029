export default [
    {
        path: 'route',
        name: 'route',
        meta: {auth: 'service.route.query', title: '航线管理'},
        component: () => import(/* webpackChunkName: "service.query" */ '@/views/service/route/query.vue')
    },
    {
        path: 'route/add',
        name: 'route.add',
        meta: {auth: 'service.route.add', title: '航线新增'},
        component: () => import(/* webpackChunkName: "service.info" */ '@/views/service/route/info.vue')
    },
    {
        path: 'route/load/:key',
        name: 'route.load',
        meta: {auth: 'service.route.load', title: '航线查看'},
        component: () => import(/* webpackChunkName: "service.info" */ '@/views/service/route/info.vue')
    },
    {
        path: 'route/edit/:key',
        name: 'route.edit',
        meta: {auth: 'service.route.save', title: '航线修改'},
        component: () => import(/* webpackChunkName: "service.info" */ '@/views/service/route/info.vue')
    },
];
