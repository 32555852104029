<template>
  <span class="table-item-link" @click="$link(link,value)" v-text="text"/>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  inheritAttrs: true,
  name: "v-wrap",
  props: {
    items: Array,
    value: [String, Number],
    link: String,
    textProp: String,
    valueProp: String,
  },
  computed: {
    text() {
      return this.$selectShow(this.items, this.value, this.textProp, this.valueProp);
    }
  }
});
</script>
<style>
</style>
