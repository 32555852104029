import {ServiceReportEntity, ServiceReportQuery} from "@/core/entity/report/report";
import {ServiceReportMainEntity} from "@/core/entity/report/reportMain";
import http from "@/core/util/http";
import {UmService} from "@/core/service/core";
class ServiceReport extends UmService<ServiceReportEntity, ServiceReportQuery>{
    constructor(private urlHeadchild: string) {
        super(urlHeadchild);
    }
    /**
     * 查询历史数据库中服务相关信息
     * @param param
     */
    public getReportMain(param:ServiceReportQuery):Promise<ServiceReportMainEntity> {
        return http.post<ServiceReportMainEntity>(this.urlHeadchild+"/reportMain.do", param);
    }

    /**
     * 查询历史数据库中服务相关信息
     * @param param
     */
    public addBatch(param:Array<ServiceReportEntity>):Promise<void> {
        return http.post<void>(this.urlHeadchild+"/addBatch.do", param);
    }

}

export  default new ServiceReport("service/serviceReport");
