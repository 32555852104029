export default {
    toDateString(time?: number): string | undefined {
        return time ? dateFormatUTC(time, 'yyyy-MM-dd') : undefined;
    },
    toTimeString(time?: number, format?: string): string | undefined {
        return time ? dateFormatUTC(time, format ?? 'hh:mm:ss') : undefined;
    },
    toDateTimeString(time?: number, format?: string): string | undefined {
        return time ? dateFormatUTC(time, format) : undefined;
    }
}

function dateFormatUTC(time: number, fmt: string = 'yyyy-MM-dd hh:mm:ss') {
    const date = new Date(time);
    const o: any = {
        "M+": date.getUTCMonth() + 1,     //月份
        "d+": date.getUTCDate(),     //日
        "h+": date.getUTCHours(),     //小时
        "m+": date.getUTCMinutes(),     //分
        "s+": date.getUTCSeconds(),     //秒
        "S": date.getMilliseconds()    //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

function dateFormatLocal(time: number, fmt: string = 'yyyy-MM-dd hh:mm:ss') {
    const date = new Date(time);
    const o: any = {
        "M+": date.getMonth() + 1,     //月份
        "d+": date.getDate(),     //日
        "h+": date.getHours(),     //小时
        "m+": date.getMinutes(),     //分
        "s+": date.getSeconds(),     //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds()    //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
