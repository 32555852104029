//提示信息
export default {
    post: {
        400: "400:请求错误！",
        403: "403:访问被拒绝！(请重新登陆)",
        404: "404:请求的地址不存在！",
        500: "500:应用服务器错误！",
        999: "未知的服务器错误！"
    },
    button:{
        query:"查 询",
        save:"保 存",
        add:"新 增",
        upload:"上 传",
        typeChoose:"筛 选",
        del:"删  除",
    },
    login: {
        rule: {
            requiredAccount: "账号不能为空！",
            requiredPassword: "验证码不能为空！"
        },
        send: {
            success: "验证码发送成功！",
            error: "验证码发送失败！"
        },
        submit: {
            error: "登录失败！",
        }
    },
    confirm: {
        del: "确认删除该记录吗？",
        addToList: "是否添加到列表？",
        serviceOver: "确定完成服务？"
    },
    validate: {
        required: "输入不能为空",
        lengthMax: "长度不能超过",//+length
        lengthEq: "长度不等于",//+length
        lengthMin: "长度不能少于",//+length
        date: "日期格式不正确",
        datetime: "日期时间格式不正确",
        email:"email格式不正确",
        intNumber: "必须为数字",
        minNumber: "输入数字必须大于",
        maxNumber: "输入数字必须小于",
        pointDigit:"小数位数不得大于",
    }
}
