export class StandardQuery {

    public offset?: number;

    public size?: number;

    public time?: number;

    public order?: string;

}


export class UmQuery {

    public key?: number;

    public sq?: StandardQuery;

}

export class UmEntity {

    /**
     * 主键
     */
    public key?: number;

    /**
     * 操作时间
     */
    public operTime?: number;

    /**
     * 操作时间
     */
    public createTime?: number;

    /**
     * 操作员
     */
    public operOperatorKey?: number;

    /**
     * 操作员
     */
    public operOperatorName?: string;

    /**
     * 客户
     */
    public operCustomerKey?: number;

    /**
     * 客户
     */
    public operCustomerName?: string;

}
