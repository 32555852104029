import tip from "@/configure/tip";

const dateReg = new RegExp(/^[1-9]\d{3}-(0?[1-9]|1[0-2])-(0?[1-9]|[1-2][0-9]|3[0-1])$/);

const emailReg=new RegExp('^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\\.[a-zA-Z]{2,3}$', '');

const numReg=new RegExp(/^(-?\d+)(\.\d+)?$/);

export default {
    //必填
    required() {
        return (value?: any) => !!value ? true : tip.validate.required
    },
    //长度
    length(value: number,type:lengthType) {
        switch (type){
            case lengthType.MAX:
                return (str?: any) =>(!!str && str.toString().length <= value) ? true : tip.validate.lengthMax + value;
            case lengthType.EQ:
                return (str?: any) =>(!!str && str.toString().length === value) ? true : tip.validate.lengthEq + value;
            case lengthType.MIN:
                return (str?: any) =>(!!str && str.toString().length >= value) ? true : tip.validate.lengthMin + value;
        }

        // return (str?: any) => (!!str && str.length <= value) ? true : tip.validate.length + value
    },
    //日期时间
    date() {
        return (date?: string): true | string => !!date && dateReg.test(date) ? true : tip.validate.date;
    },
    //邮箱
    email() {
        return (email?: string): true | string => !!email && emailReg.test(email) ? true : tip.validate.email;
    },
    /**
     * 数字校验
     * @param type
     */
    number(min:number,max:number,portDigit?:number){
        return function (num?: number) {
            if(num===void 0)
                return true;
            if(!!num && numReg.test(num.toString())){
                if(!!min&&num<min)
                    return tip.validate.minNumber + min;
                if(!!max&&num>max)
                    return tip.validate.maxNumber + max;

            }
            else
                return tip.validate.intNumber;
        }
    },
    datetime() {
    },
}

export enum lengthType{

    MIN,
    EQ,
    MAX,
}


