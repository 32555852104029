<template>
  <div id="main" class="app-main">
    <router-view/>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  data: () => ({})
});
</script>
<style>
@import './styles/overrides.scss';

html {
  overflow: hidden;
}

</style>
