export default {
    applyStatus: [
        {name: '申请中', key: 0},
        {name: '执行中', key: 1},
        {name: '已完成', key: 9},
    ],
    serviceStatus:[
        {name: '废弃', key: -1},
        {name: '停用', key: 0},
        {name: '正在服务', key: 1},
        {name: '服务完成', key: 2},
    ]
}
