import {UmEntity, UmQuery} from "@/core/entity/core";
import http from "@/core/util/http";

const URL = {
    QUERY: "/query.do",
    ADD: "/add.do",
    SAVE: "/save.do",
    DEL: "/del.do",
    LOAD: "/load.do",
    COUNT: "/count.do",
}

export class UmService<T extends UmEntity, Q extends UmQuery> {

    constructor(private urlHead: string) {
    }

    /**
     * 标准查询
     * @param {any} param 查询参数
     * @returns {Promise<T[]>} 异步返回
     */
    public query(param?: Q): Promise<T[] & { dataLength?: number }> {
        return http.post<T[] & { dataLength?: number }>(this.urlHead + URL.QUERY, param);
    }

    /**
     * 数量查询
     * @param {Q} param  查询参数
     * @returns {Promise<Array<number>>} 异步返回
     */
    public count(param?: Q): Promise<number> {
        return http.post<number>(this.urlHead + URL.COUNT, param);
    }

    /**
     * 标准插入
     * @param {T} entity 插入实体类
     * @returns {Promise<T extends >} 异步返回
     */
    public add(entity: T): Promise<T> {
        return http.post<T>(this.urlHead + URL.ADD, entity);
    }

    /**
     * 标准更新
     * @param {T} entity 更新实体类
     * @returns {Promise<T extends >} 异步返回
     */
    public save(entity: T): Promise<void> {
        return http.post<void>(this.urlHead + URL.SAVE, entity);
    }

    /**
     * 单条数据加载
     * @param {string} key 主键
     * @returns {Promise<T extends >} 异步返回
     */
    public load(key: number): Promise<T> {
        return http.post<T>(this.urlHead + URL.LOAD, {key});
    }

    /**
     * 单条数据删除
     * @param {string} key 主键
     * @returns {Promise<T extends >} 异步返回
     */
    public del(key: number): Promise<T> {
        return http.post<T>(this.urlHead + URL.DEL, {key});
    }
}
