import Vue from "vue";
import globalFunc from "@/core/store/globalFunc";
import formatter from "@/core/util/formatter";
import validate, {lengthType} from "@/core/util/validate";
import {StandardQuery, UmQuery} from "@/core/entity/core";
import {DataOptions} from "vuetify";
import VDatetimePicker from "./v-datetime-picker.vue";
import VWrap from "./v-wrap.vue";
import VDataCheckbox from "./v-data-checkbox.vue";
import VTableItemLink from "./v-table-item-link.vue";
import select from "@/configure/select";
import tip from "@/configure/tip";
import link from "./link";
import {Route} from "vue-router";

export default {
    install(vm: typeof Vue) {
        //统一网络异常提示
        vm.config.errorHandler = function (err, vm, info) {
            // @ts-ignore
            if (err.status) { // @ts-ignore
                vm.$store.commit(globalFunc.setPostStatus, err.status);
            } else
                console.error(`Error: ${err.toString()}\nInfo: ${info}`);
        };
        window.onerror = (err, source, lineno) => {
            // @ts-ignore
            if (err.status) { // @ts-ignore
                vm.$store.commit(globalFunc.setPostStatus, err.status);
            } else
                console.error(`Error: ${err.toString()}\nInfo: ${source}`);
            return true;
        }
        window.addEventListener('error', (err) => {
            // @ts-ignore
            if (err.status) { // @ts-ignore
                vm.$store.commit(globalFunc.setPostStatus, err.status);
            } else
                console.error(`Error: ${err.toString()}\n`);
        }, true);

        Object.defineProperty(vm.prototype, '$formatter', {
            get() {
                return Object.freeze(formatter);
            }
        });
        Object.defineProperty(vm.prototype, '$select', {
            get() {
                return Object.freeze(select);
            }
        });
        Object.defineProperty(vm.prototype, '$validate', {
            get() {
                return Object.freeze(validate);
            }
        });
        Object.defineProperty(vm.prototype, '$lengthType', {
            get() {
                return Object.freeze(lengthType);
            }
        });
        Object.defineProperty(vm.prototype, '$tip', {
            get() {
                return Object.freeze(tip);
            }
        });


        Vue.component("VDatetimePicker", VDatetimePicker);
        Vue.component("VWrap", VWrap);
        Vue.component("VDataCheckbox", VDataCheckbox);
        Vue.component("VTableItemLink", VTableItemLink);

        vm.mixin({
            methods: {
                $useOptionChain(target) {
                    return new Proxy(target, {
                        get: (target, propKey: string) => propKey.split('?.').reduce((a, b) => a?.[b], target)
                    })
                },
                $queryData<T extends UmQuery>(data: T, options: DataOptions, suffix?: (keyof Omit<typeof data, "sq" | "key">)[], prefix?: (keyof Omit<typeof data, "sq" | "key">)[]): typeof data {
                    const re = {...data};
                    if (suffix)
                        for (let s of suffix)
                            if (re[s] !== void 0)
                                // @ts-ignore
                                re[s] += "%";
                    if (prefix)
                        for (let s of prefix)
                            if (re[s] !== void 0)
                                // @ts-ignore
                                re[s] = "%" + re[s];
                    const {sortBy, sortDesc, page, itemsPerPage} = options;
                    if (!re.sq)
                        re.sq = new StandardQuery();
                    re.sq.offset = (page - 1) * itemsPerPage;
                    re.sq.size = itemsPerPage;
                    if (sortBy.length === 1) {
                        re.sq.order = sortBy[0] + " " + (sortDesc[0] ? "DESC" : "ASC");
                    } else
                        delete re.sq.order;
                    return re;
                },
                $confirm(text?: string): Promise<boolean> {
                    return new Promise<boolean>((callback) =>
                        this.$store.commit(globalFunc.openConfirm, {callback, text}));
                },
                $message(text?: string, color?: string, timeout: number = 2000): void {
                    this.$store.commit(globalFunc.showMessage, {text, color, timeout});
                },
                $selectShow(items: any[], value: any, textProp: string = "name", valueProp: string = "key") {
                    const v = items.find(val => val[valueProp] === value);
                    return v ? v[textProp] : "";
                },
                $link(url: keyof typeof link, key: any): Promise<Route> {
                    return this.$router.push({path: link[url] + key});
                }
            }
        });
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        readonly $formatter: typeof formatter;
        readonly $select: typeof select;
        readonly $selectShow: (select: any[], value: any, textProp?: string, valueProp?: string) => any;
        readonly $validate: typeof validate;
        readonly $tip: typeof tip;
        readonly $useOptionChain: (target: any) => any;
        readonly $queryData: <T extends UmQuery>(data: T, options: DataOptions, suffix?: (keyof Omit<typeof data, "sq">)[], prefix?: (keyof Omit<typeof data, "sq">)[]) => typeof data;
        readonly $confirm: (text?: string) => Promise<boolean>;
        readonly $message: (text?: string, color?: string, timeout?: number) => void;
        readonly $lengthType: typeof lengthType;
        readonly $link: (url: keyof typeof link, key: any) => Promise<Route>;
        // readonly $showValue: (items: Array<{ text: string } & any>, value: any, prop?: string) => string;
    }
}
