export default [
    {
        path: 'company',
        name: 'company',
        meta: {auth: 'core.company.query', title: '公司查询'},
        component: () => import(/* webpackChunkName: "company.query" */ '@/views/core/company/query.vue')
    },
    {
        path: 'company/add',
        name: 'company.add',
        meta: {auth: 'core.company.add', title: '公司新增'},
        component: () => import(/* webpackChunkName: "company.info" */ '@/views/core/company/info.vue')
    },
    {
        path: 'company/load/:key',
        name: 'company.load',
        meta: {auth: 'core.company.load', title: '公司查看'},
        component: () => import(/* webpackChunkName: "company.info" */ '@/views/core/company/info.vue')
    },
    {
        path: 'company/edit/:key',
        name: 'company.edit',
        meta: {auth: 'core.company.save', title: '公司编辑'},
        component: () => import(/* webpackChunkName: "company.info" */ '@/views/core/company/info.vue')
    },
];
