import types, {ConfirmPayload, MessagePayload, PagePayload} from "../globalFunc";

export interface State {
    openedMenuList: any[],
    pageOpenedList: PagePayload[],
    pageOpening: number,
    homeLength: number,
    menuList: any[],
    theme: string
    themeColor: string,
    postStatus: number,
    confirm: Partial<ConfirmPayload> & { show: boolean },
    message: MessagePayload & { show: boolean },
}

const state: State = {
    openedMenuList: [],
    pageOpenedList: [],
    pageOpening: 0,
    homeLength: 0,
    menuList: [],
    theme: 'dark', // 主题
    themeColor: '',
    postStatus: 0,
    confirm: {show: false},
    message: {show: false}
};
const mutations = {
    [types.showMessage](state: State, payload: MessagePayload): void {
        state.message.show = true;
        state.message.text = payload.text;
        state.message.color = payload.color;
        state.message.timeout = payload.timeout;
    },
    [types.openConfirm](state: State, payload: ConfirmPayload): void {
        state.confirm.show = true;
        state.confirm.callback = payload.callback;
        state.confirm.text = payload.text;
    },
    [types.closeConfirm](state: State, result: boolean): void {
        state.confirm.show = false;
        state.confirm.callback!(result);
        delete state.confirm.callback;
        // delete state.confirm.text;
    },
    [types.setMenuList](state: State, payload: any[]): void {
        state.menuList = payload;
    },
    [types.changeMenuTheme](state: State, theme: "dark" | "light"): void {
        state.theme = theme;
    },
    [types.changeMainTheme](state: State, mainTheme: string) {
        state.themeColor = mainTheme;
    },
    [types.openPage](state: State, page: PagePayload) {
        //清空当前
        state.pageOpenedList.map(value => value.current = false);
        //查找当前页面是否打开
        const i: number = state.pageOpenedList.findIndex(value => page.path === value.path);
        if (i >= 0) {
            state.pageOpening = i;
            state.pageOpenedList[i].current = true;
        } else {
            page.current = true;
            state.pageOpenedList.push(page);
            state.pageOpening = state.pageOpenedList.length - 1;
        }
    },
    [types.closePage](state: State, path: string) {
        const index = state.pageOpenedList.findIndex(value => value.path === path);
        state.pageOpenedList.splice(index, 1);
    },
    [types.closeAllPage](state: State) {
        // state.pageOpenedList.splice(1,state.pageOpenedList.length-1);
        state.pageOpenedList.length = 0;
    },
    [types.closeOtherPage](state: State) {
        const item: PagePayload | undefined = state.pageOpenedList.find(value => value.current);
        if (state.pageOpenedList.length > 1) {
            state.pageOpenedList.splice(1, state.pageOpenedList.length - 1);
            if (item && state.pageOpenedList[0].path !== item.path)
                state.pageOpenedList.push(item);
        }
    },
    [types.setHomePage](state: State, page: PagePayload) {
        if (state.pageOpenedList.length === 0)
            state.pageOpenedList.push(page);
        else
            state.pageOpenedList[0] = page;
    },
    [types.setTabTitle](state: State, page: PagePayload) {
        const tab = state.pageOpenedList.find(value => value.path === page.path);
        if (tab) {
            tab.title = page.title;
            tab.edit = page.edit;
        }
    },
    [types.setPostStatus](state: State, status: number) {
        state.postStatus = status;
    }
};
export default {
    state,
    mutations
};
