<template>
  <v-menu ref="menu" v-model="show" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field ref="input" v-model="text" @change="change" v-bind="$attrs" append-icon="mdi-calendar"
                    @click:append="appendClick"/>
    </template>
    <v-date-picker v-if="showDate" v-model="date" @input="dateClick"/>
    <v-time-picker v-if="showTime" v-model="time" scrollable @click:minute="timeClick"/>
  </v-menu>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  inheritAttrs: true,
  name: "v-datetime-picker",
  model: {prop: 'value', event: 'input'},
  inject: ["form"],
  props: {
    value: {type: Number},
    type: {type: String},
    readonly: {type: Boolean},
  },
  data() {
    return {
      show: false,
      showDate: true,
      showTime: false,
      date: "",
      time: "00:00",
      text: "",
      last: "",
      raw: <undefined | number>this.value
    }
  },
  mounted() {
    this.valueChange(this.value);
  },
  methods: {
    appendClick() {
      // @ts-ignore
      if (!this.$refs.input.isReadonly)
        this.show = true;
      // console.log("appendClick");
    },
    dateClick() {
      if (this.type === "date") {
        this.show = false;
        this.showDate = true;
        this.text = this.date;
      } else {
        this.showTime = true;
        this.showDate = false;
        this.text = this.date + " " + this.time;
      }
      this.last = this.text;
      this.change();
    },
    timeClick() {
      //@ts-ignore
      this.$refs.menu.save(this.time);
      this.showTime = false;
      this.showDate = true;
      this.text = this.date + " " + (this.time ?? this.time!.substring(0, 5));
      this.last = this.text;
      this.change();
    },
    change() {
      if (this.text.length === 0)
        this.raw = void 0;
      else if (this.type === "date" && this.text.length === 10)
        this.raw = new Date(this.text + " 00:00:00 UTC").getTime();
      else if (this.type !== "date" && this.text.length === 16)
        this.raw = new Date(this.text + " UTC").getTime();
      else
        this.text = this.last;
      this.$emit('input', this.raw);
    },
    valueChange(val: any) {    // 监听父组件传递数据，更新内部值
      if (val) {
        if (this.type === "date") {
          this.date = this.$formatter.toDateString(val)!;
          this.text = this.date;
        } else if (this.type !== "date") {
          this.date = this.$formatter.toDateString(val)!;
          this.time = this.$formatter.toTimeString(val, 'hh:mm')!;
          this.text = this.$formatter.toDateTimeString(val, 'yyyy-MM-dd hh:mm')!;
        }
      } else {
        this.date = "";
        this.time = "";
        this.text = "";
      }
      this.raw = val;
      this.last = this.text;
    }
  },
  watch: {
    value(val) {
      this.valueChange(val);
    },
    show(val) {
      if (val) {
        this.showDate = true;
        this.showTime = false;
      }
    },
  },
});
</script>
