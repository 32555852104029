export default [
    {
        path: 'test/d',
        name: 'test.d',
        meta: { title: 'D测试页'},
        component: () => import(/* webpackChunkName: "test.d" */ '@/views/test/d/index.vue')
    },
    {
        path: 'test/s',
        name: 'test.s',
        meta: { title: 'S测试页'},
        component: () => import(/* webpackChunkName: "test.s" */ '@/views/test/s/index.vue')
    },
    {
        path: 'test/g',
        name: 'test.g',
        meta: { title: 'G测试页'},
        component: () => import(/* webpackChunkName: "test.g" */ '@/views/test/g/index.vue')
    }
];
