interface Item {
    text: string,
    icon?: string,
    path?: string,
    auth?: string,
    children?: Item[]
}

//图标在 https://materialdesignicons.com/
export default [
    {
        text: '业务操作',
        icon: 'mdi-rotate-orbit',
        children: [
            {text: '业务服务', path: '/service', auth: 'service.service.query'},
            // {text: '报告维护', path: '/serviceReport', auth: 'service.serviceReport.reportMain'},
            // {text: 'AIS列表', path: '/track', auth: 'service.aisTrack.query'}
        ]
    },
    // {
    //     text: '航线操作',
    //     icon: 'mdi-rotate-orbit',
    //     children: [
    //         {text: '航线管理', path: '/route', auth: 'service.route.query'},
    //     ]
    // },
    {
        text: '基础信息',
        icon: 'mdi-card-text',
        children: [
            // {text: '国家信息', path: '/country', auth: 'common.country.query'},
            // {text: '港口信息', path: '/port', auth: 'common.port.query'},
            {text: '客户公司信息', path: '/company', auth: 'core.company.query'},
            {text: '客户信息', path: '/user', auth: 'core.user.query'},
            {text: '业务员信息', path: '/sale', auth: 'core.user.query'},
        ]
    },
    {
        text: '权限管理',
        icon: 'mdi-card-text',
        children: [
            {text: '用户管理', path: '/operator', auth: 'operator.operator.query'},
        ]
    },
    // {
    //     text: '测试界面',
    //     icon: 'mdi-card-text',
    //     children: [
    //         {text: 'D测试页', path: '/test/d'},
    //         {text: 'S测试页', path: '/test/s'},
    //         {text: 'G测试页', path: '/test/g'},
    //
    //     ]
    // },
    // {icon: 'mdi-card-account-details', text: '密码修改', path: '/password'},
] as Item[];
