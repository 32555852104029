import {UmService} from "@/core/service/core";
import {ServiceEntity, ServiceQuery} from "@/core/entity/service/service";
import {
    ServiceWeatherEntity,
    ServiceWeatherFileEntity,
    ServiceWeatherFileQuery,
    ServiceWeatherQuery
} from "@/core/entity/report/weather";
import {OldServiceEntity, OldServiceQuery} from "@/core/entity/service/oldService";
import http from "@/core/util/http";
import {ServiceRoutingFileEntity, ServiceRoutingFileQuery} from "@/core/entity/report/routing";


class ReportRoutingFileService extends UmService<ServiceRoutingFileEntity, ServiceRoutingFileQuery>{
    constructor(private urlHeadchild: string) {
        super(urlHeadchild)
    }
    /**
     * 查询历史数据库中服务相关信息
     * @param param
     */
    public addBatch(param:ServiceRoutingFileEntity[]):Promise<void> {
        return http.post<void>(this.urlHeadchild+"/addBatch.do", param);
    }
}
export  default new ReportRoutingFileService("service/serviceRoutingFile");
