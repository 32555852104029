import http from "@/core/util/http";
import {OldServiceEntity, OldServiceQuery} from "@/core/entity/service/oldService";

class OldService {
    constructor(private urlHeadchild: string) {
    }
    /**
     * 查询历史数据库中服务相关信息
     * @param param
     */
    public getOldServiceInfo(param:OldServiceQuery):Promise<OldServiceEntity[]> {
        return http.post<OldServiceEntity[]>(this.urlHeadchild+"/queryByNo.do", param);
    }
}
export  default new OldService("old/service");
