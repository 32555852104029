import {UmService} from "@/core/service/core";
import { FileEntity, FileQuery} from "@/core/entity/file/file";
import http from "@/core/util/http";

class file extends UmService<FileEntity, FileQuery>{
    constructor(private urlHeadchild: string) {
        super(urlHeadchild)
    }
    /**
     * 上传单文件
     * @param {any} param 查询参数
     * @returns {Promise<T[]>} 异步返回
     */
    public upFileSingle(param: any): Promise<FileEntity> {
        return http.postMultipart<FileEntity>(this.urlHeadchild+"/upFileSingle.do", param);
    }
    /**
     * 标准查询
     * @param {any} param 查询参数
     * @returns {Promise<T[]>} 异步返回
     */
    public upFileBatch(param: any): Promise<FileEntity[]> {
        return http.postMultipart<FileEntity[]>(this.urlHeadchild+"/upFileBatch.do", param);
    }
}

export default new file("service/serviceFile");
