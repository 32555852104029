export default [
    {
        path: 'port',
        name: 'port',
        meta: {auth: 'common.port.query', title: '港口查询'},
        component: () => import(/* webpackChunkName: "port.query" */ '@/views/common/port/query.vue')
    },
    {
        path: 'port/add',
        name: 'port.add',
        meta: {auth: 'common.port.add', title: '港口新增'},
        component: () => import(/* webpackChunkName: "port.info" */ '@/views/common/port/info.vue')
    },
    {
        path: 'port/load/:key',
        name: 'port.load',
        meta: {auth: 'common.port.load', title: '港口查看'},
        component: () => import(/* webpackChunkName: "port.info" */ '@/views/common/port/info.vue')
    },
    {
        path: 'port/edit/:key',
        name: 'port.edit',
        meta: {auth: 'common.port.save', title: '港口编辑'},
        component: () => import(/* webpackChunkName: "port.info" */ '@/views/common/port/info.vue')
    },
];
