<template>
  <v-snackbar v-model="$store.state.standard.message.show" app top :color="$store.state.standard.message.color"
              elevation="24" :timeout="$store.state.standard.message.timeout">
    {{ $store.state.standard.message.text }}
  </v-snackbar>
</template>
<script lang="ts">
import Vue from "vue";
import tip from "@/configure/tip";
import globalFunc from "@/core/store/globalFunc";

export default Vue.extend({
  name: "MessageSnackbar",
  data: () => ({}),
});
</script>
