import FetchClient from "./FetchClient";
import system from "@/configure/system";

export interface HttpClient {
    post<T = any>(url: string, data?: any): Promise<T>;
    postMultipart<T = any>(url: string, data?: any): Promise<T>;
}

export class AccessException {
    constructor(public status: number, public message?: string) {
    }
}

export default new FetchClient(system.url.base);
