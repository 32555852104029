export default [
    {
        path: 'user',
        name: 'user',
        meta: {auth: 'core.user.query', title: '客户查询'},
        component: () => import(/* webpackChunkName: "user.query" */ '@/views/core/user/query.vue')
    },
    {
        path: 'user/add',
        name: 'user.add',
        meta: {auth: 'core.user.add', title: '客户新增'},
        component: () => import(/* webpackChunkName: "user.info" */ '@/views/core/user/info.vue')
    },
    {
        path: 'user/load/:key',
        name: 'user.load',
        meta: {auth: 'core.user.load', title: '客户查看'},
        component: () => import(/* webpackChunkName: "user.info" */ '@/views/core/user/info.vue')
    },
    {
        path: 'user/edit/:key',
        name: 'user.edit',
        meta: {auth: 'core.user.save', title: '客户编辑'},
        component: () => import(/* webpackChunkName: "user.info" */ '@/views/core/user/info.vue')
    },
];
