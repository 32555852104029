import Vue from 'vue';
import service from "../service";
export interface SessionTeam{
    key:number;
    name: string;
}

export interface SessionUser {
    key: number;
    name: string;
    teamList:SessionTeam[]
}

let permissions: Set<string> | undefined = void 0;
let sessionUser: SessionUser | undefined = void 0;

/**
 * 加载用户权限
 */
function load() {
    return Promise.all([
        service.security.permission().then(value => permissions = new Set<string>(value)),
        service.security.user().then(value => sessionUser = value)
    ]);
}

/**
 * 当前权限校验
 * @param name
 */
function auth(name: string): boolean {
    if (permissions === void 0 || sessionUser === void 0)
        return false;
    else if (name === void 0 || name.length === 0)
        return true;
    else
        return permissions.has(name);
}

export default {

    /**
     * 登录
     * @param account account
     * @param password password
     */
    async login(account: string, password: string): Promise<boolean> {
        try {
            await service.security.login(account, password);
            await load();
            return true;
        } catch (e) {
            if (e.status === 400)
                return false;
            throw e;
        }
    },

    /**
     * 注销
     */
    async logout() {
        await service.security.logout();
        permissions = void 0;
        sessionUser = void 0;
    },

    /**
     * 权限校验
     * @param name permission name
     */
    async auth(name: string): Promise<boolean> {
        if (permissions === void 0) {
            return load().then(() => auth(name)).catch(() => false);
        }
        return auth(name);
    },

    install(vm: typeof Vue) {
        Object.defineProperty(vm.prototype, '$user', {
            get() {
                return Object.freeze(sessionUser);
            }
        });
        vm.prototype.$auth = auth;
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        readonly $user: SessionUser;
        readonly $auth: typeof auth;
    }
}
