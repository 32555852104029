<template>
  <v-simple-checkbox :value="value===1" v-bind="$attrs" @input="input" :ripple="false"/>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  inheritAttrs: true,
  name: "v-data-checkbox",
  model: {prop: 'value'},
  props: {
    value: {type: Number},
  },
  data() {
    return {}
  },
  mounted() {
    // this.data = this.value === 1;
  },
  inject: ["form"],
  methods: {
    input(val: boolean) {
      this.$emit("input", val ? 1 : 0);
    }
  }
});
</script>

<style scoped>

</style>
