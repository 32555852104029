import Vue from 'vue'
import App from './app.vue'
import router from './core/router'
import store from './core/store'
import vuetify from './core/plugins/vuetify';
import security from "./core/plugins/security";
import standard from "@/core/plugins/standard";
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import globalFunc from "@/core/store/globalFunc";

Vue.config.productionTip = false;
Vue.use(security);
Vue.use(standard);
const vm = new Vue({router, store, vuetify, render: h => h(App)}).$mount('#app');
//异常捕获
window.addEventListener("unhandledrejection", (e) => {
    e.preventDefault();
    if (e.reason.status) {
        vm.$store.commit(globalFunc.setPostStatus, e.reason.status);
    } else
        console.error(`Error: ${e.reason.toString()}\n`);
}, true);
