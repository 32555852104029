import {AccessException, HttpClient} from "@/core/util/http/index";
import tips from "@/configure/tip";

const headers = new Headers({
    'Content-Type': 'application/json'
});
const MultipartHeaders = new Headers({
    // 'Content-Type': 'multipart/form-data'
});

function createRequest(body?: any): RequestInit {
    return {
        body: body ? JSON.stringify(body) : void 0,
        headers,
        credentials: 'same-origin',
        method: 'POST',
    }
}
function createMultipartRequest(body?: any): RequestInit {
    return {
        body: body,
        headers:MultipartHeaders,
        credentials: 'same-origin',
        method: 'POST',
    }
}

export default class FetchClient implements HttpClient {

    constructor(private baseURL: string) {
    }
    public async postMultipart<T>(url: string, data?: any): Promise<T> {
        const response = await fetch(this.baseURL + url, createMultipartRequest(data));
        switch (response.status) {
            case 200:
                if (response.headers.get("content-length") === "0") {
                    return void 0 as unknown as T;
                } else {
                    let re = await response.json() as T;
                    if (response.headers.has("data-length")) { // @ts-ignore
                        re.dataLength = parseInt(response.headers.get("data-length"));
                    }
                    return re;
                }
            case 400:
                throw new AccessException(response.status, await response.text());
            case 403:
            case 404:
            case 500:
                throw new AccessException(response.status, tips.post[response.status]);
            default:
                throw new AccessException(response.status);
        }
    }
    public async post<T>(url: string, data?: any): Promise<T> {
        const response = await fetch(this.baseURL + url, createRequest(data));
        switch (response.status) {
            case 200:
                if (response.headers.get("content-length") === "0") {
                    return void 0 as unknown as T;
                } else {
                    let re = await response.json() as T;
                    if (response.headers.has("data-length")) { // @ts-ignore
                        re.dataLength = parseInt(response.headers.get("data-length"));
                    }
                    return re;
                }
            case 400:
                throw new AccessException(response.status, await response.text());
            case 403:
            case 404:
            case 500:
                throw new AccessException(response.status, tips.post[response.status]);
            default:
                throw new AccessException(response.status);
        }
    }
}
