import {RouteConfig} from "vue-router";
import service from "./service/service";
import route from "./service/route";
import port from "./common/port";
import company from "./core/company";
import user from "./core/user";
import aisTrack from "./service/aisTrack";
import operator from "@/core/router/routes/core/operator";
import test from "./test/test";
import sale from "./core/sale";

const menu: RouteConfig[] = [
    {
        path: '',
        name: 'home',
        meta: {auth: '', title: '首页'}
    }
];

menu.push(...service);
menu.push(...route);
menu.push(...port);
menu.push(...company);
menu.push(...user);
menu.push(...aisTrack);
menu.push(...operator);
menu.push(...test);
menu.push(...sale);
export default menu;
