import system from "@/configure/system";
import http from "../../util/http";
import {SessionUser} from "../../plugins/security";

export default {
    /**
     * 用户登录
     * @param {string} account 账户
     * @param {string} password 密码
     * @returns {Promise<any>} 异步返回
     */
    login(account: string, password: string): Promise<boolean> {
        return http.post(system.url.login, {account, password, type: 1});
    },

    /**
     * 注销
     * @returns {Promise<never>}
     */
    logout(): Promise<never> {
        return http.post(system.url.logout);
    },

    permission(): Promise<Array<string>> {
        return http.post(system.url.permission);
    },

    user(): Promise<SessionUser> {
        return http.post(system.url.user);
    },

    send(account: string): Promise<void> {
        return http.post(system.url.operator.sendLoginVerifyByMail, {account});
    }
}
