export default [
    {
        path: 'sale',
        name: 'sale',
        meta: {auth: 'core.user.query', title: '业务员查询'},
        component: () => import(/* webpackChunkName: "user.query" */ '@/views/core/sale/query.vue')
    },
    {
        path: 'sale/add',
        name: 'sale.add',
        meta: {auth: 'core.user.add', title: '业务员新增'},
        component: () => import(/* webpackChunkName: "user.info" */ '@/views/core/sale/info.vue')
    },
    {
        path: 'sale/load/:key',
        name: 'sale.load',
        meta: {auth: 'core.user.load', title: '业务员查看'},
        component: () => import(/* webpackChunkName: "user.load" */ '@/views/core/sale/info.vue')
    },
    {
        path: 'sale/edit/:key',
        name: 'sale.edit',
        meta: {auth: 'core.user.save', title: '业务员编辑'},
        component: () => import(/* webpackChunkName: "user.edit" */ '@/views/core/sale/info.vue')
    },
];
