export default [
    {
        path: 'operator',
        name: 'operator',
        meta: {auth: 'operator.operator.query', title: '用户查询'},
        component: () => import(/* webpackChunkName: "operator.query" */ '@/views/operator/operator/query.vue')
    },
    {
        path: 'operator/add',
        name: 'operator.add',
        meta: {auth: 'operator.operator.add', title: '用户新增'},
        component: () => import(/* webpackChunkName: "operator.query" */ '@/views/operator/operator/info.vue')
    },
    {
        path: 'operator/load/:key',
        name: 'operator.load',
        meta: {auth: 'operator.operator.load', title: '用户查看'},
        component: () => import(/* webpackChunkName: "operator.query" */ '@/views/operator/operator/info.vue')
    },
    {
        path: 'operator/edit/:key',
        name: 'operator.edit',
        meta: {auth: 'operator.operator.save', title: '用户编辑'},
        component: () => import(/* webpackChunkName: "operator.query" */ '@/views/operator/operator/info.vue')
    },
];
