
import {AisHis, AisInfo, AisQuery} from "@/core/entity/ais/ais";
import http from "@/core/util/http";

class AisService {
    constructor(private urlHeadchild: string) {
    }

    /**
     * 标准查询
     * @param {any} param 查询参数
     * @returns {Promise<T[]>} 异步返回
     */
    public infoBatch(param: any): Promise<AisInfo[]> {
        return http.post<AisInfo[]>(this.urlHeadchild+"/infoBatch.do", param);
    }

    /**
     * 查询航运数据处理平台数据
     * @param {any} param 查询参数
     * @returns {Promise<T[]>} 异步返回
     */
    public history(param: any): Promise<AisHis[]> {
        return http.post<AisHis[]>(this.urlHeadchild+"/history.do", param);
    }
    /**
     * 查询气导备份数据-单多均可 不可查询间隔
     * @param {any} param 查询参数
     * @returns {Promise<T[]>} 异步返回
     */
    public hisAll(param: any): Promise<AisHis[]> {
        return http.post<AisHis[]>("old/aisHis/hisAll.do", param);
    }
    /**
     * 查询气导备份数据
     * @param {any} param 查询参数
     * @returns {Promise<T[]>} 异步返回
     */
    public hisSingle(param: any): Promise<AisHis[]> {
        return http.post<AisHis[]>("old/aisHis/hisSingle.do", param);
    }

}

export  default new AisService("common/ais");
