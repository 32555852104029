import {UmService} from "@/core/service/core";
import {UserRoleEntity, UserRoleQuery} from "@/core/entity/core/user";
import http from "@/core/util/http";


class userRole extends UmService<UserRoleEntity, UserRoleQuery>{
    constructor(private urlHeadchild: string) {
        super(urlHeadchild);
    }
    public delByUserKey(param: any):Promise<UserRoleEntity> {
        return http.post<UserRoleEntity>(this.urlHeadchild+"/delByUserKey.do", param);
    }
}

export default new userRole("core/userRole");


