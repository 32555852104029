import {UmService} from "@/core/service/core";
import {ServiceEntity, ServiceQuery} from "@/core/entity/service/service";
import http from "@/core/util/http";

class ServiceService extends UmService<ServiceEntity, ServiceQuery>{
    constructor(private urlHeadchild: string) {
        super(urlHeadchild)
    }
    /**
     * 标准查询
     * @param {any} param 查询参数
     * @returns {Promise<T[]>} 异步返回
     */
    public serviceOver(param: any): Promise<ServiceEntity> {
        return http.post<ServiceEntity>(this.urlHeadchild+"/serviceOver.do", param);
    }
}
export default new ServiceService("service/service");
