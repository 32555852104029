export default {
    //访问地址
    url: {
        //base url
        base: "./",
        login: "login.do",
        logout: "logout.do",
        permission: "permission.do",
        user: "user.do",
        operator: {
            sendLoginVerifyByMail: "manage-core/sendLoginVerifyByMail.do",
        }
    }
}
