import {UmService} from "@/core/service/core";
import {ServiceEntity, ServiceQuery} from "@/core/entity/service/service";
import {
    ServiceWeatherEntity,
    ServiceWeatherFileEntity,
    ServiceWeatherFileQuery,
    ServiceWeatherQuery
} from "@/core/entity/report/weather";
import {OldServiceEntity, OldServiceQuery} from "@/core/entity/service/oldService";
import http from "@/core/util/http";


class ReportWeatherFileService extends UmService<ServiceWeatherFileEntity, ServiceWeatherFileQuery>{
    constructor(private urlHeadchild: string) {
        super(urlHeadchild)
    }
    /**
     * 查询历史数据库中服务相关信息
     * @param param
     */
    public addBatch(param:ServiceWeatherFileEntity[]):Promise<void> {
        return http.post<void>(this.urlHeadchild+"/addBatch.do", param);
    }
}
export  default new ReportWeatherFileService("service/serviceWeatherFile");
